import { arbitrageTypes } from '../../constants';

export const filterType = (item, hash) => {
    const type = hash.slice(1);
    return (
        (type === arbitrageTypes.free && item.Profit <= 10) ||
        item.Kind === hash.slice(1)
    );
};

export const filterExchanges = (item, exchanges) =>
    !exchanges?.length ||
    (exchanges.includes(item.Ex1) && exchanges.includes(item.Ex2)) ||
    item.Ex1.startsWith('defilama') ||
    item.Ex2.startsWith('defilama') ||
    item.Ex1.startsWith('1inch') ||
    item.Ex2.startsWith('1inch') ||
    item.Ex1.startsWith('stonfi') ||
    item.Ex2.startsWith('stonfi') ||
    item.Ex1.startsWith('dedust') ||
    item.Ex2.startsWith('dedust');

export const filterAmount = (item, maxAmount) =>
    !maxAmount || item.AskAmountUSDT < maxAmount;

export const filterProfit = (item, profit) => !profit || item.Profit >= profit;

export const filterBlacklist = (item, blacklist) =>
    !blacklist?.find((symbol) => item.Symbol.includes(symbol.toLowerCase()));

export const filterBlockchain = (item, blockchains) =>
    item.Kind === arbitrageTypes.cexToCex ||
    !blockchains?.find((chain) => chain === item.Chain);

export const filterHiddens = (item, hidden) =>
    !hidden?.find((hiddenItem) => item.Symbol === hiddenItem.symbol);
