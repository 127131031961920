import { clockIcon, eyeIcon } from '../../../../assets/svg';
import { arbitrageTypes } from '../../../../constants';

export const configureFilters = (filters, type) => {
    const chains = [
        {
            title: 'BSC',
            value: 'bsc',
        },
        {
            title: 'Ethereum',
            value: 'eth',
        },
        {
            title: 'Polygon',
            value: 'polygon',
        },
        {
            title: 'Arbitrum',
            value: 'arbitrum',
        },
        {
            title: 'Solana',
            value: 'solana',
        },
        {
            title: 'Base',
            value: 'base',
        },
        {
            title: 'Ton',
            value: 'ton',
        },
        {
            title: 'Tron',
            value: 'tron',
        },
        {
            title: 'ZkSync',
            value: 'zksync',
        },
        {
            title: 'Optimism',
            value: 'optimism',
        },
        {
            title: 'Celo',
            value: 'celo',
        },
    ];

    const exchanges = [
        {
            value: 'binance',
        },
        {
            value: 'bitfinex',
        },
        {
            value: 'bybit',
        },
        {
            value: 'gate',
        },
        {
            value: 'huobi',
        },
        {
            value: 'jupiter',
            onlyFor: arbitrageTypes.cexToDex,
        },
        {
            value: 'kucoin',
        },
        {
            value: 'mexc',
        },
        {
            value: 'okx',
        },
        {
            value: 'poloniex',
        },
        {
            value: 'bitget',
        },
        {
            value: 'lbank',
        },
        {
            value: 'bitmart',
        },
        {
            value: 'bingx',
        },
        {
            value: 'coinex',
        },
        {
            value: 'xt',
        },
    ];

    const sort = [
        {
            title: 'Profit',
            value: 'profit',
        },
        {
            title: 'Spotted',
            value: 'spotted',
        },
    ];

    const filteredExchanges = exchanges
        .filter((item) => !item?.onlyFor || item.onlyFor === type)
        .map(({ value }) => ({
            title: value[0].toUpperCase() + value.slice(1),
            value,
        }));

    return [
        type === arbitrageTypes.cexToCex || type === arbitrageTypes.cexToDex
            ? {
                  filterType: 'checkbox',
                  filterKey: 'exchanges',
                  filterLabel: 'Exchanges',
                  filterOptions: filteredExchanges,
                  selectedFilterOptions: filters.exchanges,
              }
            : null,
        type === arbitrageTypes.dexToDex || type === arbitrageTypes.cexToDex
            ? {
                  filterType: 'select',
                  filterKey: 'blockchain',
                  filterLabel: 'Blockchain',
                  filterOptions: chains,
                  selectedFilterOption: filters.blockchain,
              }
            : null,
        {
            filterType: 'input',
            filterKey: 'profit',
            filterLabel: 'Мониторинг цены',
            filterSubLabel: 'Установите значение желаемой прибыли',
            filterValue: filters.profit,
        },
        {
            filterType: 'input',
            filterKey: 'min_amount',
            filterLabel: 'Min Обьем $',
            filterValue: filters.min_amount,
        },
        {
            filterType: 'input',
            filterKey: 'max_amount',
            filterLabel: 'Max Обьем $',
            filterValue: filters.max_amount,
        },
        {
            filterType: 'input',
            filterKey: 'hidden_time',
            filterIcon: eyeIcon,
            filterLabel: 'Ограничение времени пропуска сделки (min)',
            filterValue: filters.hidden_time,
        },
        {
            filterType: 'input',
            filterKey: 'update_time',
            filterIcon: clockIcon,
            filterLabel: ' Задержка обновлений (сек)',
            filterValue: filters.update_time,
        },
        {
            filterType: 'checkbox',
            filterKey: 'sort',
            filterLabel: 'Сортировка',
            filterOptions: sort,
            selectedFilterOptions: filters.sort,
        },
    ];
};
