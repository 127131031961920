import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useSocket = (
    onMessageReceived,
    onRequestData,
    kind,
    autoRefresh
) => {
    const url = process.env.REACT_APP_BASE_URL;
    const user = useSelector((state) => state.auth.user);
    const token = useSelector((state) => state.auth.token);

    useEffect(() => {
        let socket;

        if (user && autoRefresh) {
            socket = new WebSocket(`wss://${url}ws`);

            socket.addEventListener('open', () => {
                console.log('WebSocket connection opened');

                const subscribeMessage = {
                    event: 'subscribe',
                    id: -99,
                    kind: kind,
                    token: token,
                };
                socket.send(JSON.stringify(subscribeMessage));
            });

            socket.addEventListener('message', (event) => {
                const responseData = JSON.parse(event.data);
                console.log(responseData);

                if (responseData.data) {
                    onMessageReceived(responseData.data);
                }

                if (responseData.id === -1) {
                    window.location.reload();
                }
            });

            socket.addEventListener('close', (event) => {
                console.log('WebSocket connection closed:', event);
            });

            socket.addEventListener('error', (event) => {
                console.error('WebSocket error:', event);
            });
        } else if (user && !autoRefresh) {
            console.log('im worken');
            onRequestData();
        }

        return () => {
            if (socket) {
                socket.close();
            }
        };
    }, [user, autoRefresh, kind, token, onMessageReceived, onRequestData, url]);
};
