import React from 'react';

import { crossIcon, eyeIcon } from '../../../../../assets/svg';
import {
    getTimeDifference,
    setDateText,
} from '../../../../../utils/functions/date';
import Svg from '../../../../layout/Svg/Svg';

import styles from './styles.module.scss';

const TrackerPageListItem = ({
    Symbol,
    Spoted,
    CurrentPrice,
    PrevPrice,
    TimeMark,
    Difference,
}) => {
    const spotedText = getTimeDifference(Spoted);
    const isUp = CurrentPrice >= PrevPrice;

    const onAddToHidden = () => {
        console.log(`${Symbol} added to hidden`);
    };
    const onAddToBlackList = () => {
        console.log(`${Symbol} added to blacklist`);
    };
    return (
        <div className={styles['trackerListItem']}>
            <div className={styles['trackerListItem__header']}>
                <h5 className={styles['trackerListItem__title']}>
                    {Symbol.toUpperCase().replace('USDT', '')}
                </h5>
                <div className={styles['trackerListItem__headerBtns']}>
                    <>
                        <button
                            onClick={onAddToHidden}
                            className={styles['trackerListItem__hiderBtn']}
                        >
                            <Svg
                                className={styles['trackerListItem__hideIcon']}
                                id={eyeIcon}
                            />
                        </button>
                        <button
                            className={styles['trackerListItem__hiderBtn']}
                            onClick={onAddToBlackList}
                        >
                            <Svg
                                className={styles['trackerListItem__crossIcon']}
                                id={crossIcon}
                            />
                        </button>
                    </>
                </div>
            </div>

            <div
                className={`${styles['trackerListItem__resultBlock']} ${isUp ? styles['trackerListItem__resultBlock_up'] : styles['trackerListItem__resultBlock_fall']}`}
            >
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {isUp ? 'Up' : 'Fall'} in{' '}
                    </span>
                    {Difference}%
                </p>

                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        Current price:{' '}
                    </span>
                    {CurrentPrice}
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        Previous price:{' '}
                    </span>
                    {PrevPrice}
                </p>
                <p className={styles['trackerListItem__resultBlockText']}>
                    <span
                        className={
                            isUp
                                ? styles['trackerListItem__resultBlockText_up']
                                : styles[
                                      'trackerListItem__resultBlockText_fall'
                                  ]
                        }
                    >
                        {TimeMark}
                    </span>
                </p>
            </div>

            <div className={styles['trackerListItem__footer']}>
                <p className={styles['trackerListItem__footerText']}>
                    Spotted: {setDateText(spotedText)}
                </p>
            </div>
        </div>
    );
};

export default TrackerPageListItem;
