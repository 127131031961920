import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormValue } from '../../../../hooks/useFormValue';
import NewPortalProvider from '../../../../providers/NewPortalProvider';
import TransitionProvider from '../../../../providers/TransitionProvider';
import { setFilters } from '../../../../redux/action/filters';
import Backdrop from '../../../layout/Backdrop/Backdrop';
import CrossBtn from '../../../layout/CrossBtn/CrossBtn';
import { configureFilters } from './config';
import styles from './FilterModal.module.scss';
import FilterModalItem from './FilterModalItem';
import ModalButtons from './ModalButtons';

const FilterModal = ({ show, onClose, pageType, onSuccessFilterApply }) => {
    const dispatch = useDispatch();
    const userFilters = useSelector((state) => state.filters.filters);

    const [chosenBlockchain, setChosenBlockchain] = useState(
        userFilters?.blockchain || 'Выберите blockchain'
    );
    const [isBlockChainSelectOpened, setIsBlockchainSelectOpened] =
        useState(false);

    const filters = configureFilters(userFilters || {}, pageType) || [];

    const initialData = filters.reduce((acc, cur) => {
        if (cur) {
            acc[cur.filterKey] =
                cur.filterType === 'checkbox'
                    ? cur.selectedOptions || []
                    : cur.filterType === 'select'
                      ? cur.selectedFilterOption
                      : cur.filterValue || 0;
        }
        return acc;
    }, {});
    initialData.kind = pageType;

    const { onChange, formData, setFormData } = useFormValue(initialData);

    const onToggleCheckBox = (name, value) => {
        setFormData((prevState) => ({
            ...prevState,
            [name]: prevState[name]?.includes(value)
                ? prevState[name].filter((filterItem) => filterItem !== value)
                : [...(prevState[name] || []), value],
        }));
    };

    const onToggleBlockchainSelect = () => {
        setIsBlockchainSelectOpened((prev) => !prev);
    };

    const onChooseSelect = (title, value, name) => {
        setChosenBlockchain(title);
        setIsBlockchainSelectOpened(false);
        setFormData((prevState) => ({
            ...prevState,
            [name]: [value],
        }));
    };

    const onSubmit = (e) => {
        e.preventDefault();

        const resData = Object.keys(formData).reduce((acc, cur) => {
            acc[cur] = Array.isArray(formData[cur])
                ? formData[cur]
                : isNaN(+formData[cur])
                  ? formData[cur]
                  : +formData[cur];
            return acc;
        }, {});

        const onSuccess = () => {
            dispatch(onSuccessFilterApply());
            onClose(true);
        };

        dispatch(setFilters(resData, onSuccess));
    };

    return (
        <>
            <Backdrop inProp={show} onClose={onClose} highZIndex />
            <NewPortalProvider>
                <TransitionProvider
                    className={styles['filterModal']}
                    inProp={show}
                    style="right"
                >
                    <CrossBtn
                        btnClassName={styles['filterModal__crossBtn']}
                        onClick={onClose}
                    />
                    <form
                        className={`${styles['filterModal__container']} scrollbarDef`}
                        method="POST"
                        onSubmit={onSubmit}
                    >
                        {filters.map((filter, index) => (
                            <FilterModalItem
                                key={index}
                                filter={filter}
                                formData={formData}
                                onToggleCheckBox={onToggleCheckBox}
                                onToggleSelect={onToggleBlockchainSelect}
                                onChooseSelect={onChooseSelect}
                                onInputChange={onChange}
                                chosenOption={chosenBlockchain}
                                isSelectOpened={isBlockChainSelectOpened}
                            />
                        ))}
                        <ModalButtons onCancel={onClose} />
                    </form>
                </TransitionProvider>
            </NewPortalProvider>
        </>
    );
};

export default FilterModal;
