import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { arbitrageTypes, subscriptionTypes } from '../../../constants';
import { useSocket } from '../../../hooks/useSocket';
import {
    getArbitrage,
    requestArbitrage,
} from '../../../redux/action/arbitrage';
import { changeUserData } from '../../../redux/action/auth';
import { arbitragePagePath } from '../../../router/path';
import {
    filterAmount,
    filterBlacklist,
    filterBlockchain,
    filterExchanges,
    filterHiddens,
    filterProfit,
    filterType,
} from '../../../utils/functions/filters';
import DataLoader from '../../layout/DataLoader/DataLoader';
import ArbitragePageListItem from './ArbitragePageListItem/ArbitragePageListItem';

import { setAutoRefresh } from '../../../redux/action/filters';
import styles from './ArbitragePageList.module.scss';

function ArbitragePageList() {
    const { hash } = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth.user);
    const data = useSelector((state) => state.arbitrage.data);
    const filters = useSelector((state) => state.filters.filters);
    const loading = useSelector((state) => state.arbitrage.getLoading);
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);

    useEffect(() => {
        dispatch(setAutoRefresh());

        if (!hash) {
            navigate(`${arbitragePagePath}#free`);
        }
    }, [dispatch, hash, navigate]);

    useSocket(
        useCallback((payload) => dispatch(getArbitrage(payload)), [dispatch]),
        useCallback(() => dispatch(requestArbitrage()), [dispatch]),
        'arb',
        autoRefresh
    );

    const onAddToBlackList = (symbol) => {
        dispatch(changeUserData({ blacklist: [...filters.blacklist, symbol] }));
    };

    const onAddToHidden = (item) => {
        const newItem = {
            symbol: item.Symbol,
            ex1: item.Ex1,
            ex2: item.Ex2,
        };
        const oldData = filters.hidden || [];
        if (
            !oldData.find(
                (oldItem) =>
                    oldItem.symbol === item.Symbol &&
                    oldItem.ex1 === item.Ex1 &&
                    oldItem.ex2 === item.Ex2
            )
        ) {
            dispatch(changeUserData({ hidden: [...oldData, newItem] }));
        }
    };

    const filteredData = data.filter(
        (item) =>
            filterType(item, hash) &&
            filterAmount(item, filters.max_amount) &&
            filterExchanges(item, filters.exchanges) &&
            filterProfit(item, filters.profit) &&
            filterBlacklist(item, filters.blacklist) &&
            filterBlockchain(item, filters.blockchains) &&
            filterHiddens(item, filters.hidden)
    );

    return (
        <div className={styles['arbitrageList']}>
            {filteredData.length > 0 ? (
                <div className={styles['arbitrageList__container']}>
                    {filteredData.map((item, index) => (
                        <ArbitragePageListItem
                            key={index}
                            onAddToBlackList={onAddToBlackList}
                            onAddToHidden={onAddToHidden}
                            isArb={user?.subscription === subscriptionTypes.arb}
                            isCexToDex={
                                hash.slice(1) === arbitrageTypes.cexToDex
                            }
                            isDexToDex={
                                hash.slice(1) === arbitrageTypes.dexToDex
                            }
                            {...item}
                        />
                    ))}
                </div>
            ) : (
                <DataLoader loading={loading} isEmpty={!filteredData.lenght} />
            )}
        </div>
    );
}

export default ArbitragePageList;
