import React from 'react';

import { useLocation } from 'react-router-dom';
import { requestArbitrage } from '../../../redux/action/arbitrage';
import FiltersBlock from '../../global/FiltersBlock';
import styles from './ArbitragePageHeader.module.scss';

function ArbitragePageHeader() {
    const { hash } = useLocation();

    return (
        <div className={styles['arbitrageHeader']}>
            <h5
                className={`${styles['arbitrageHeader__title']} pageRouteTitle`}
            >
                Главная &gt; Arbitrage &gt;
                {hash
                    ? ` ${hash.slice(1).replace('-', ' - ').toUpperCase()} Arbitrage`
                    : ''}
            </h5>
            <FiltersBlock onSuccessFilterApply={requestArbitrage} />
        </div>
    );
}

export default ArbitragePageHeader;
