import { getLSItem, setLSItem } from '../../utils/functions/localStorage';
import { lsProps } from '../../utils/lsProps';
import {
    SET_AUTO_REFRESH,
    SET_FILTERS_ERROR,
    SET_FILTERS_LOADING_START,
    SET_FILTERS_SUCCESS,
    TOGGLE_AUTO_REFRESH,
} from '../types';
import { authConfig, createFilterUrl, fetchRequest } from './fetchTools';

export const setFilters = (filters, clb) => async (dispatch, getState) => {
    dispatch({ type: SET_FILTERS_LOADING_START });
    const user = getState().auth.user;
    const reqBody = {
        id: user.id,

        field: Object.keys(filters),
        value: Object.values(filters),
    };

    try {
        const res = await fetchRequest(
            createFilterUrl,
            'POST',
            reqBody,
            authConfig()
        );
        console.log(res);

        dispatch({ type: SET_FILTERS_SUCCESS, payload: filters });

        setLSItem(lsProps.filters, filters);

        if (clb) {
            clb();
        }
    } catch (error) {
        console.log(error);
        dispatch({ type: SET_FILTERS_ERROR, error });
    }
};

export const setAutoRefresh = (isToggle) => (dispatch) => {
    const autoRefresh = getLSItem(lsProps.autoRefresh, true);
    if (isToggle) {
        dispatch({ type: TOGGLE_AUTO_REFRESH });
        setLSItem(lsProps.autoRefresh, !autoRefresh);
    } else {
        const payload = autoRefresh === undefined ? true : autoRefresh;
        if (payload !== autoRefresh) setLSItem(lsProps.autoRefresh, payload);
        dispatch({ type: SET_AUTO_REFRESH, payload });
    }
};
