import React from 'react';
import { crossIcon } from '../../../../assets/svg';
import TransitionProvider from '../../../../providers/TransitionProvider';
import Svg from '../../../layout/Svg/Svg';

import styles from './styles.module.scss';

const FilterApplyPopup = ({ isOpened, onClose }) => {
    return (
        <TransitionProvider
            style={'top'}
            inProp={isOpened}
            className={styles['filterApplyPopup']}
        >
            <div className={styles['filterApplyPopup__container']}>
                <p className={styles['filterApplyPopup__text']}>
                    Фильтр с заданными параметрами применен
                </p>
                <button
                    className={styles['filterApplyPopup__btn']}
                    onClick={onClose}
                >
                    <Svg
                        id={crossIcon}
                        className={styles['filterApplyPopup__crossIcon']}
                    />
                </button>
            </div>
        </TransitionProvider>
    );
};

export default FilterApplyPopup;
