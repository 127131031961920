import { combineReducers } from 'redux';
import { authReducer } from './authReducer';
import { cexOutflowsReducer } from './cexOutflowsReducer';
import { newsReducer } from './newsReducer';

import { arbitrageReducer } from './arbitrageReducer';
import { filtersReducer } from './filters';

export default combineReducers({
    auth: authReducer,
    news: newsReducer,
    cexOutflows: cexOutflowsReducer,
    arbitrage: arbitrageReducer,
    filters: filtersReducer,
});
