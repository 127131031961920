import React from 'react';

import { useLocation } from 'react-router-dom';
import styles from './styles.module.scss';

const TrackerPageHeader = () => {
    const { hash } = useLocation();

    return (
        <h5
            className={`${styles['TrackerPageHeader__routeTitle']} pageRouteTitle`}
        >
            Главная &gt; Tracker &gt;{' '}
            {hash ? ` ${hash.slice(1).toUpperCase()}` : ''}
        </h5>
    );
};

export default TrackerPageHeader;
