import React from 'react';
import { crossIcon, eyeIcon } from '../../../../assets/svg';
import {
    getTimeDifference,
    setDateText,
} from '../../../../utils/functions/date';
import Svg from '../../../layout/Svg/Svg';
import {
    exchangeColors,
    formatExchangeTitle,
    openNewBrowserWindow,
    setOrderText,
} from './config';

import styles from './ArbitragePageListItem.module.scss';

function ArbitragePageListItem({
    AskCount,
    AskAmount,
    BidAmount,
    AskAmountUSDT,
    BidAmountUSDT,
    BidPrice,
    AskPrice,
    BidCount,
    Chain,
    FeeUSDT,
    Profit,
    SpotFee,
    Symbol,
    onAddToBlackList,
    onAddToHidden,
    Ex1,
    Ex2,
    isArb,
    Link1,
    Link2,
    Straight,
    Spoted,
    isCexToDex,
    isDexToDex,
    ProfitPct,
    Contract,
    ContractLink,
    ContractAsk,
    ContractAskLink,
    ContractBid,
    ContractBidLink,
    DexScreenerLink,
    HoldersLink,
    GoPlusLink,
    BuyTaxAsk,
    SellTaxAsk,
    BuyTaxBid,
    SellTaxBid,
    SellTax,
    BuyTax,
    CanChangeAsk,
    CanChangeBid,
    CanChange,
}) {
    const spotedText = getTimeDifference(Spoted);

    let exAsk = Straight ? Ex1 : Ex2;
    let exBid = !Straight ? Ex1 : Ex2;
    const linkAsk = Straight ? Link1 : Link2;
    const linkBid = !Straight ? Link1 : Link2;

    const exchangeItem = exchangeColors.find((item) => {
        if (isCexToDex && (item.ex === exBid || item.ex === exAsk)) {
            return true;
        } else {
            return false;
        }
    });
    const askItem = exchangeColors.find(
        (item) => isDexToDex && item.ex === exAsk
    );
    const bidItem = exchangeColors.find(
        (item) => isDexToDex && item.ex === exBid
    );

    const copyToClipboard = (text) => {
        navigator.clipboard
            .writeText(text)
            .then(() => {
                // alert('Адрес Контракта скопирован в буфер обмена!');
            })
            .catch((err) => {
                // console.error('Не удалось скопировать текст: ', err);
            });
    };

    return (
        <div className={styles['arbitrageListItem']}>
            <div className={styles['arbitrageListItem__header']}>
                <h5 className={styles['arbitrageListItem__title']}>
                    {Symbol.toUpperCase().replace('USDT', '')}
                </h5>
                <div className={styles['arbitrageListItem__headerBtns']}>
                    {isArb ? (
                        <>
                            <button
                                onClick={() =>
                                    onAddToHidden({ Symbol, Ex1, Ex2 })
                                }
                                className={
                                    styles['arbitrageListItem__hiderBtn']
                                }
                            >
                                <Svg
                                    className={
                                        styles['arbitrageListItem__hideIcon']
                                    }
                                    id={eyeIcon}
                                />
                            </button>
                            <button
                                className={
                                    styles['arbitrageListItem__hiderBtn']
                                }
                                onClick={() => onAddToBlackList(Symbol)}
                            >
                                <Svg
                                    className={
                                        styles['arbitrageListItem__crossIcon']
                                    }
                                    id={crossIcon}
                                />
                            </button>
                        </>
                    ) : null}
                </div>
            </div>
            <div
                className={`${styles['arbitrageListItem__resultBlock']} ${styles['arbitrageListItem__resultBlock_output']}`}
            >
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_green']
                        }
                    >
                        BUY:{' '}
                    </span>
                    {AskAmount.toFixed(4)} ({AskAmountUSDT.toFixed(4)} USDT)
                </p>
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_green']
                        }
                    >
                        ASK:{' '}
                    </span>
                    {AskPrice} - {AskCount} {setOrderText(AskCount)}
                </p>
                {!isDexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                        >
                            Сеть:{' '}
                        </span>
                        {Chain}
                    </p>
                ) : null}
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_green']
                        }
                    >
                        Комиссия Перевода:{' '}
                    </span>
                    {FeeUSDT.toFixed(4)}$
                </p>

                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_green']
                        }
                    >
                        Купить:{' '}
                    </span>
                    <a
                        className={
                            styles['arbitrageListItem__resultBlockLinkText']
                        }
                        target={'_blank'}
                        rel="noreferrer"
                        href={linkAsk}
                    >
                        {formatExchangeTitle(exAsk)}
                    </a>
                </p>
                {!isCexToDex && isDexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles[
                                    'arbitrageListItem__resultBlockText_green'
                                ]
                            }
                            onClick={() => copyToClipboard(ContractAsk)}
                            style={{ cursor: 'pointer' }}
                        >
                            Адрес Контракта:
                        </span>
                        {/* <span className={styles["arbitrageListItem__resultBlockText_green"]}>Адрес Контракта: </span> */}
                        <a
                            className={
                                styles['arbitrageListItem__resultBlockLinkText']
                            }
                            target={'_blank'}
                            rel="noreferrer"
                            href={ContractAskLink}
                        >
                            {ContractAsk}
                        </a>
                    </p>
                ) : null}
                {!isCexToDex && isDexToDex ? (
                    <div
                        className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{
                            backgroundColor: askItem.color,
                        }}
                    >
                        <h6
                            className={
                                styles['arbitrageListItem__exhcangeBlockText']
                            }
                            style={{
                                color: askItem.colorText || '#fff',
                            }}
                        >
                            {askItem.name}
                        </h6>
                    </div>
                ) : null}
            </div>
            <div
                className={`${styles['arbitrageListItem__resultBlock']} ${styles['arbitrageListItem__resultBlock_input']}`}
            >
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_red']
                        }
                    >
                        SELL:{' '}
                    </span>
                    {BidAmount.toFixed(4)} ({BidAmountUSDT.toFixed(4)} USDT)
                </p>
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_red']
                        }
                    >
                        BID:{' '}
                    </span>
                    {BidPrice} - {BidCount} {setOrderText(BidCount)}
                </p>
                {!isDexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                        >
                            Сеть:{' '}
                        </span>
                        {Chain}
                    </p>
                ) : null}
                <p className={styles['arbitrageListItem__resultBlockText']}>
                    <span
                        className={
                            styles['arbitrageListItem__resultBlockText_red']
                        }
                    >
                        Продать:{' '}
                    </span>
                    <a
                        className={
                            styles['arbitrageListItem__resultBlockLinkText']
                        }
                        target={'_blank'}
                        rel="noreferrer"
                        href={linkBid}
                    >
                        {formatExchangeTitle(exBid)}
                    </a>
                </p>

                {isCexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                            onClick={() => copyToClipboard(Contract)}
                            style={{ cursor: 'pointer' }}
                        >
                            Адрес Контракта:
                        </span>

                        <a
                            className={
                                styles['arbitrageListItem__resultBlockLinkText']
                            }
                            target={'_blank'}
                            rel="noreferrer"
                            href={ContractLink}
                        >
                            {Contract}
                        </a>
                    </p>
                ) : null}
                {!isCexToDex && isDexToDex ? (
                    <p className={styles['arbitrageListItem__resultBlockText']}>
                        <span
                            className={
                                styles['arbitrageListItem__resultBlockText_red']
                            }
                            onClick={() => copyToClipboard(ContractBid)}
                            style={{ cursor: 'pointer' }}
                        >
                            Адрес Контракта:
                        </span>
                        {/* <span className={styles["arbitrageListItem__resultBlockText_red"]}>Адрес Контракта: </span> */}
                        <a
                            className={
                                styles['arbitrageListItem__resultBlockLinkText']
                            }
                            target={'_blank'}
                            rel="noreferrer"
                            href={ContractBidLink}
                        >
                            {ContractBid}
                        </a>
                    </p>
                ) : null}
                {!isCexToDex && isDexToDex ? (
                    <div
                        className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{
                            backgroundColor: bidItem.color,
                        }}
                    >
                        <h6
                            className={
                                styles['arbitrageListItem__exhcangeBlockText']
                            }
                            style={{
                                color: bidItem.colorText || '#fff',
                            }}
                        >
                            {bidItem.name}
                        </h6>
                    </div>
                ) : null}

                {isCexToDex && !isDexToDex ? (
                    <div
                        className={styles['arbitrageListItem__exhcangeBlock']}
                        style={{
                            backgroundColor: exchangeItem?.color,
                        }}
                    >
                        <h6
                            className={
                                styles['arbitrageListItem__exhcangeBlockText']
                            }
                            style={{
                                color: exchangeItem?.colorText || '#fff',
                            }}
                        >
                            {exchangeItem?.name}
                        </h6>
                    </div>
                ) : null}
            </div>
            {isCexToDex && !isDexToDex ? (
                <p className={`${styles['arbitrageListItem__infoText']} `}>
                    SellTax:{' '}
                    <span
                        className={`
                            ${
                                SellTax === '0'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : SellTax !== '0'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                    >
                        {SellTax}%{' '}
                    </span>
                    | BuyTax:{' '}
                    <span
                        className={`
                            ${
                                BuyTax === '0'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : BuyTax !== '0'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                    >
                        {BuyTax}%{' '}
                    </span>
                    | TaxMod:{' '}
                    <span
                        className={`
                            ${
                                CanChange === 'disable'
                                    ? styles[
                                          'arbitrageListItem__resultBlockText_green'
                                      ]
                                    : CanChange === 'enable'
                                      ? styles[
                                            'arbitrageListItem__resultBlockText_red'
                                        ]
                                      : ''
                            }`}
                    >
                        {CanChange}
                    </span>
                </p>
            ) : null}

            <p className={styles['arbitrageListItem__profitText']}>
                Общая комиссия:
                <span className={styles['arbitrageListItem__profitText_blue']}>
                    {' '}
                    {SpotFee.toFixed(4)}$
                </span>
            </p>
            <p className={styles['arbitrageListItem__profitText']}>
                Profit:
                <span className={styles['arbitrageListItem__profitText_blue']}>
                    {' '}
                    {Profit.toFixed(4)}$ ({ProfitPct.toFixed(4)}%)
                </span>
            </p>
            {!isCexToDex && isDexToDex && (
                <button
                    className={styles['arbitrageListItem__exhcangeBlock']}
                    style={{
                        backgroundColor: '#6F758E',
                    }}
                >
                    <h5
                        className={styles['arbitrageListItem__bridge']}
                        onClick={() =>
                            openNewBrowserWindow(
                                ContractAsk,
                                askItem.id,
                                ContractBid,
                                bidItem.id,
                                AskAmount
                            )
                        }
                    >
                        Bridge
                    </h5>
                </button>
            )}
            <div className={styles['arbitrageListItem__footer']}>
                <p className={styles['arbitrageListItem__footerText']}>
                    Spotted: {setDateText(spotedText)}
                </p>
            </div>
            <div className={styles['arbitrageListItem__footer']}>
                {isCexToDex && !isDexToDex ? (
                    <div>
                        <a
                            className={styles['arbitrageListItem__footerText']}
                            target={'_blank'}
                            rel="noreferrer"
                            href={HoldersLink}
                        >
                            Holders{' '}
                        </a>
                        |
                        <a
                            className={styles['arbitrageListItem__footerText']}
                            target={'_blank'}
                            rel="noreferrer"
                            href={DexScreenerLink}
                        >
                            {' '}
                            DexScreener
                        </a>
                        |
                        <a
                            className={styles['arbitrageListItem__footerText']}
                            target={'_blank'}
                            rel="noreferrer"
                            href={GoPlusLink}
                        >
                            {' '}
                            GoPlus
                        </a>
                    </div>
                ) : null}
            </div>
        </div>
    );
}

export default ArbitragePageListItem;
