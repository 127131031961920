import React from 'react';

import { externalLink } from '../../../../../assets/svg';
import Svg from '../../../../layout/Svg/Svg';

import {
    getTimeDifference,
    setDateText,
} from '../../../../../utils/functions/date';
import styles from './styles.module.scss';

const CEXOutflowsListItem = ({
    Amount,
    Token,
    AmountUSDT,
    Exchange,
    Timestamp,
    Tx,
}) => {
    const spotedText = setDateText(getTimeDifference(Timestamp), 'ru');

    const roundedAmount = Math.round(Amount * 100) / 100;
    const roundedAmountUSDT = Math.round(AmountUSDT * 100) / 100;

    return (
        <div className={styles['CEXOutflowsListItem']}>
            <div className={styles['CEXOutflowsListItem__info']}>
                <div className={styles['CEXOutflowsListItem__info_text']}>
                    <span
                        className={
                            styles['CEXOutflowsListItem__info_textTitle']
                        }
                    >
                        Вывод{' '}
                    </span>
                    <div>
                        <span>
                            <span
                                className={
                                    styles[
                                        'CEXOutflowsListItem__info_amountBTC'
                                    ]
                                }
                            >
                                {' '}
                                {roundedAmount} {Token}{' '}
                            </span>
                            <span
                                className={
                                    styles[
                                        'CEXOutflowsListItem__info_amountUSDT'
                                    ]
                                }
                            >
                                ({roundedAmountUSDT} USDT)
                            </span>
                        </span>{' '}
                        из{' '}
                        <span
                            className={
                                styles['CEXOutflowsListItem__info_exchange']
                            }
                        >
                            {Exchange}{' '}
                        </span>
                        <span>{spotedText}</span>
                    </div>
                </div>
                <a
                    className={styles['CEXOutflowsListItem__info_link']}
                    href={Tx}
                    target="_blank"
                >
                    Детали транзакции
                    <Svg
                        className={styles['CEXOutflowsListItem__info_linkIcon']}
                        id={externalLink}
                    />
                </a>
            </div>
        </div>
    );
};

export default CEXOutflowsListItem;
