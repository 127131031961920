import React from 'react';
import DataLoader from '../../../layout/DataLoader/DataLoader';

import TrackerPageListItem from './TrackerPageListItem';
import styles from './styles.module.scss';

const mockData = [
    {
        Symbol: 'kamausdt',
        Spoted: 1727617108,
        PrevPrice: 0.1,
        CurrentPrice: 0.15,
        TimeMark: 'In 5 min',
        Difference: 50,
    },
    {
        Symbol: 'rareusdt',
        Spoted: 1727617205,
        PrevPrice: 0.3,
        CurrentPrice: 0.15,
        TimeMark: 'In 20 min',
        Difference: 100,
    },
    {
        Symbol: 'example',
        Spoted: 1727717108,
        PrevPrice: 0.1,
        CurrentPrice: 0.11,
        TimeMark: 'In 9 min',
        Difference: 10,
    },
    {
        Symbol: 'mockcoin',
        Spoted: 1727617408,
        PrevPrice: 0.3,
        CurrentPrice: 0.15,
        TimeMark: 'In 5 min',
        Difference: 50,
    },
    {
        Symbol: 'examplecoinusdt',
        Spoted: 1727667108,
        PrevPrice: 0.1,
        CurrentPrice: 0.12,
        TimeMark: 'In 10 min',
        Difference: 20,
    },
    {
        Symbol: 'sampleusdt',
        Spoted: 1727619108,
        PrevPrice: 0.1,
        CurrentPrice: 0.15,
        TimeMark: 'In 9 min',
        Difference: 3,
    },
];

const TrackerPageList = () => {
    const loading = false;

    return (
        <div className={styles['TrackerList']}>
            <div className={styles['TrackerList__container']}>
                {mockData.length > 0 ? (
                    mockData.map((item, index) => (
                        <TrackerPageListItem key={index} {...item} />
                    ))
                ) : (
                    <DataLoader
                        loading={loading}
                        isEmpty={mockData.length === 0}
                    />
                )}
            </div>
        </div>
    );
};

export default TrackerPageList;
