import React from 'react';
import { useSelector } from 'react-redux';
import { Scrollbar } from 'react-scrollbars-custom';

import ArbitragePageHeader from '../../components/ArbitragePage/ArbitragePageHeader/ArbitragePageHeader';
import ArbitragePageList from '../../components/ArbitragePage/ArbitragePageList/ArbitragePageList';
import { subscriptionTypes } from '../../constants';

function ArbitragePage() {
    const user = useSelector((state) => state.auth.user);

    return (
        <Scrollbar
            style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh',
            }}
        >
            <div className={'gradientBg'}>
                <div className="container">
                    {user?.subscription === subscriptionTypes.arb ? (
                        <ArbitragePageHeader />
                    ) : null}
                    <ArbitragePageList />
                </div>
            </div>
        </Scrollbar>
    );
}

export default ArbitragePage;
