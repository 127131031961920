import { getAnalytics } from 'firebase/analytics';
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';
import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    editEmailImage,
    editUsernameImage,
    passwordKeyImage,
} from '../../../assets/images';
import { changePassword, changeUserData } from '../../../redux/action/auth';
import { getLSItem } from '../../../utils/functions/localStorage';
import { lsProps } from '../../../utils/lsProps';
import SettingsForm from './SettingsForm/SettingsForm';
import styles from './SettingsPageBlock.module.scss';

import {
    baseUrl as apiBaseUrl,
    authConfig,
} from '../../../redux/action/fetchTools.js';

const changePassFields = [
    {
        icon: passwordKeyImage,
        placeholder: 'Старый пароль',
        key: 'old_password',
        type: 'password',
    },
    {
        icon: passwordKeyImage,
        placeholder: 'Новый пароль',
        key: 'new_password',
        type: 'password',
    },
];

const changeUserFields = (user) => [
    {
        icon: editEmailImage,
        placeholder: 'Email',
        key: 'email',
        type: 'email',
        initialValue: user?.email || '',
    },
    {
        icon: editUsernameImage,
        placeholder: 'Имя пользователя',
        key: 'username',
        initialValue: user?.username || '',
    },
];

function SettingsPageBlock() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);
    const changePasswordLoading = useSelector(
        (state) => state.auth.changePasswordLoading
    );
    const changePasswordError = useSelector(
        (state) => state.auth.changePasswordError
    );
    const editDataLoading = useSelector((state) => state.auth.editDataLoading);
    const editDataError = useSelector((state) => state.auth.editDataError);
    const [isNotificationsActive, setIsNotificationsActive] = useState(
        getLSItem(lsProps.usePushNot, true)
    );

    const apiKey = process.env.REACT_APP_API_KEY;
    const authDomain = process.env.REACT_APP_AUTH_DOMAIN;
    const projectId = process.env.REACT_APP_PROJECT_ID;
    const storageBucket = process.env.REACT_APP_STORAGE_BUCKET;
    const messagingSenderId = process.env.REACT_APP_MESSAGING_SENDER_ID;
    const appId = process.env.REACT_APP_APP_IP;
    const measurmentId = process.env.REACT_APP_MEASUREMENT_ID;
    const vapidKey = process.env.REACT_APP_VAPID_KEY;

    const firebaseConfig = {
        apiKey,
        authDomain,
        projectId,
        storageBucket,
        messagingSenderId,
        appId,
        measurmentId,
    };

    const toggleNotificationsActivity = () => {
        if (isNotificationsActive) {
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
            const messaging = getMessaging(app);

            getToken(messaging, {
                vapidKey: vapidKey,
            })
                .then((currentToken) => {
                    if (currentToken) {
                        console.log('Token:', currentToken);

                        fetch(`${apiBaseUrl}/deleteToken`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                            },
                            body: JSON.stringify({ token: currentToken }),
                            ...authConfig(),
                        })
                            .then((response) => response.json())
                            .then((data) => {
                                console.log('Server response:', data);
                            })
                            .catch((error) => {
                                console.error(
                                    'Error sending token to server:',
                                    error
                                );
                            });
                    } else {
                        console.log('No registration token available.');
                    }
                })
                .catch((err) => {
                    console.log(
                        'An error occurred while retrieving token. ',
                        err
                    );
                });
            // }
            setIsNotificationsActive(false);
            // let usePush = getLSItem(lsProps.usePushNot, true)
            // subscribePush((sub) => dispatch(
            // onUserSubscribe(sub, usePush, () => setIsNotificationsActive(true)))
            // )

            // const pushendpoint = getLSItem(lsProps.pushendpoint, true)
            // const payload = user.push_subscription.filter(item => item.endpoint !== pushendpoint)
            // const onSuccess = () => {
            //     removeLSItem(lsProps.pushendpoint)
            //     setLSItem(lsProps.usePushNot, false)
            //     setIsNotificationsActive(false)
            // }

            // dispatch(
            //     changeUserData(
            //         { push_subscription: payload },
            //         false,
            //         onSuccess
            //     ))
        } else {
            const app = initializeApp(firebaseConfig);
            const analytics = getAnalytics(app);
            const messaging = getMessaging(app);

            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    getToken(messaging, {
                        vapidKey: vapidKey,
                    })
                        .then((currentToken) => {
                            if (currentToken) {
                                console.log('Token:', currentToken);

                                // Отправьте токен на сервер
                                fetch(`${apiBaseUrl}/addToken`, {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify({
                                        token: currentToken,
                                    }),
                                    ...authConfig(),
                                })
                                    .then((response) => response.json())
                                    .then((data) => {
                                        console.log('Server response:', data);
                                    })
                                    .catch((error) => {
                                        console.error(
                                            'Error sending token to server:',
                                            error
                                        );
                                    });
                            } else {
                                console.log('No registration token available.');
                            }
                        })
                        .catch((err) => {
                            console.log(
                                'An error occurred while retrieving token. ',
                                err
                            );
                        });
                    // }
                    setIsNotificationsActive(true);
                    // let usePush = getLSItem(lsProps.usePushNot, true)
                    // subscribePush((sub) => dispatch(
                    // onUserSubscribe(sub, usePush, () => setIsNotificationsActive(true)))
                    // )
                } else if (permission === 'denied') {
                } else {
                }
            });
        }
    };

    const onChangePassword = (formData, clb) => {
        dispatch(changePassword({ ...formData, email: user.email }, clb));
    };

    const onEditUserData = (formData, clb) => {
        dispatch(changeUserData(formData, true, clb));
    };

    const memoizedChangeUserFields = useMemo(
        () => changeUserFields(user),
        [user]
    );

    return (
        <div className={styles['settingsBlock']}>
            <h5
                className={`${styles['settingsBlock__routeTitle']} pageRouteTitle`}
            >
                Главная &gt; Settings
            </h5>
            <div className={styles['settingsBlock__main']}>
                <div className={styles['settingsBlock__mainTopBlock']}>
                    <div className={styles['settingsBlock__box']}>
                        <h6 className={styles['settingsBlock__boxSubtitle']}>
                            Смена данных
                        </h6>
                        <h4 className={styles['settingsBlock__boxTitle']}>
                            Общие
                        </h4>
                        {user ? (
                            <SettingsForm
                                fields={memoizedChangeUserFields}
                                onSubmit={onEditUserData}
                                error={editDataError}
                                loading={editDataLoading}
                                submitSuccessText={'Данные обновлены.'}
                            />
                        ) : null}
                    </div>
                    <div className={styles['settingsBlock__box']}>
                        <h6 className={styles['settingsBlock__boxSubtitle']}>
                            Смена пароля
                        </h6>
                        <h4 className={styles['settingsBlock__boxTitle']}>
                            Безопасность
                        </h4>
                        {user ? (
                            <SettingsForm
                                fields={changePassFields}
                                onSubmit={onChangePassword}
                                error={changePasswordError}
                                submitSuccessText={'Пароль обновлен.'}
                                loading={changePasswordLoading}
                            />
                        ) : null}
                    </div>
                </div>
                <div className={styles['settingsBlock__box']}>
                    <h6 className={styles['settingsBlock__boxSubtitle']}>
                        Примечание: обязательно разрешайте уведомления, когда
                        браузер запрашивает ваше разрешение.
                    </h6>
                    <h4 className={styles['settingsBlock__boxTitle']}>
                        Push-уведомления браузера
                    </h4>
                    <div className={styles['settingsBlock__notificationBlock']}>
                        <div>
                            <h6
                                className={
                                    styles[
                                        'settingsBlock__notificationBlockTitle'
                                    ]
                                }
                            >
                                Арбитраж
                            </h6>
                            <p
                                className={
                                    styles[
                                        'settingsBlock__notificationBlockText'
                                    ]
                                }
                            >
                                Получать уведомления браузера о новых
                                возможностях арбитража.
                            </p>
                        </div>
                        <button
                            onClick={toggleNotificationsActivity}
                            className={`${styles['settingsBlock__notificationBlockBtn']} ${isNotificationsActive ? styles['settingsBlock__notificationBlockBtn_active'] : ''}`}
                        ></button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SettingsPageBlock;
