import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { subscriptionTypes } from '../../../constants';
import { changeUserData } from '../../../redux/action/auth';
import { setAutoRefresh } from '../../../redux/action/filters';
import { getCreatedAt } from '../../../utils/functions/date';
import FilterApplyPopup from './FilterApplyPopup';
import FilterModal from './FilterModal';
import NonVisibleItemsModal from './NonVisbleItemsModal';
import OptionsBlock from './OptionsBlock';

const FiltersBlock = ({ onSuccessFilterApply }) => {
    const dispatch = useDispatch();
    const { hash } = useLocation();
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const user = useSelector((state) => state.auth.user);
    const filters = useSelector((state) => state.filters.filters);

    const pageType = hash.slice(1);
    const isSubscribed = user.subscription === subscriptionTypes.arb;

    const [isBlackListModalOpened, setIsBlackListModalOpened] = useState(false);
    const [isHiddenItemsModalOpened, setIsHiddenItemsModalOpened] =
        useState(false);
    const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
    const [isFilterApplied, setIsFilterApplied] = useState(false);
    const filterNotModalIntervalRef = useRef(null);

    const openBlackListModal = () => setIsBlackListModalOpened(true);
    const closeBlackListModal = () => setIsBlackListModalOpened(false);
    const openHiddenItemsModal = () => setIsHiddenItemsModalOpened(true);
    const closeHiddenItemsModal = () => setIsHiddenItemsModalOpened(false);
    const openFilterModal = () => setIsFilterModalOpened(true);
    const closeFilterModal = (isCreated) => {
        setIsFilterModalOpened(false);
        if (isCreated && typeof isCreated == 'boolean')
            openFilterAppliedModal();
    };
    const openFilterAppliedModal = () => setIsFilterApplied(true);
    const closeFilterAppliedModal = () => setIsFilterApplied(false);
    const toggleAutoRefresh = () => dispatch(setAutoRefresh(true));

    useEffect(() => {
        let interval = null;
        if (filters.hidden && filters.hidden_time) {
            interval = setInterval(
                () => {
                    const hidden = filters.hidden.filter((hidden) => {
                        const now = new Date();
                        const createdAt = getCreatedAt(hidden.created_at);
                        createdAt.setHours(createdAt.getHours() + 4);
                        createdAt.setMinutes(
                            createdAt.getMinutes() + filters.hidden
                        );
                        return createdAt > now;
                    });
                    if (hidden.length !== filters.hidden.length) {
                        dispatch(changeUserData({ hidden }));
                    }
                },
                filters.hidden_time * 60 * 1000
            );
        }

        return () => {
            if (interval) clearInterval(interval);
        };
    }, [filters, dispatch]);

    useEffect(() => {
        if (isFilterApplied) {
            filterNotModalIntervalRef.current = setTimeout(() => {
                closeFilterAppliedModal();
            }, 2000);
        } else {
            if (filterNotModalIntervalRef.current)
                clearInterval(filterNotModalIntervalRef.current);
        }
    }, [isFilterApplied]);

    const onRemoveBlackListItem = (item) => {
        dispatch(
            changeUserData({
                blacklist: filters.blacklist.filter(
                    (filterItem) => filterItem !== item
                ),
            })
        );
    };

    const onRemoveHiddenItem = (item) => {
        dispatch(
            changeUserData({
                hidden: filters.hidden?.filter(
                    (filterItem) => filterItem.symbol !== item
                ),
            })
        );
    };

    return (
        <div>
            <NonVisibleItemsModal
                isOpened={isBlackListModalOpened}
                onClose={closeBlackListModal}
                title={'Blacklist'}
                items={filters.blacklist || []}
                onRemoveItem={onRemoveBlackListItem}
            />
            <NonVisibleItemsModal
                isOpened={isHiddenItemsModalOpened}
                onClose={closeHiddenItemsModal}
                title={'Hidden'}
                items={filters.hidden?.map((item) => item.symbol) || []}
                onRemoveItem={onRemoveHiddenItem}
            />
            {user.subscription === subscriptionTypes.arb && (
                <FilterModal
                    pageType={pageType}
                    show={isFilterModalOpened}
                    onClose={closeFilterModal}
                    onSuccessFilterApply={onSuccessFilterApply}
                />
            )}
            <FilterApplyPopup
                isOpened={isFilterApplied}
                onClose={closeFilterAppliedModal}
            />
            <OptionsBlock
                isSubscribed={isSubscribed}
                autoRefresh={autoRefresh}
                toggleAutoRefresh={toggleAutoRefresh}
                openBlackListModal={openBlackListModal}
                openFilterModal={openFilterModal}
                openHiddenItemsModal={openHiddenItemsModal}
            />
        </div>
    );
};

export default FiltersBlock;
