import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useSocket } from '../../../../hooks/useSocket';
import {
    getCEXOutflows,
    requestCEXOutflows,
} from '../../../../redux/action/cexOurflow';
import DataLoader from '../../../layout/DataLoader/DataLoader';
import CEXOutflowsListItem from './CEXOutflowsListItem';

import { setAutoRefresh } from '../../../../redux/action/filters';
import styles from './styles.module.scss';

const CEXOutflowsList = () => {
    const data = useSelector((state) => state.cexOutflows.data);
    const loading = useSelector((state) => state.cexOutflows.getLoading);
    const autoRefresh = useSelector((state) => state.filters.autoRefresh);
    const dispatch = useDispatch();

    useEffect(() => dispatch(setAutoRefresh()), [dispatch]);

    useSocket(
        useCallback((payload) => dispatch(getCEXOutflows(payload)), [dispatch]),
        useCallback(() => dispatch(requestCEXOutflows()), [dispatch]),
        'cex_outflow',
        autoRefresh
    );

    return (
        <div className={styles['CEXOutflowsList']}>
            {data.length > 0 ? (
                <div className={styles['CEXOutflowsList__container']}>
                    {data.map((item, index) => (
                        <CEXOutflowsListItem key={index} {...item} />
                    ))}
                </div>
            ) : (
                <DataLoader loading={loading} isEmpty={!data.length} />
            )}
        </div>
    );
};

export default CEXOutflowsList;
